"use client";
import SignInForm from "@/components/Forms/signInForm/signInForm";
import Image from "next/image";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
function SignIn() {
  const [isWebsiteUser, setIsWebsiteUser] = useState(true);

  const isMobile = () => {
    if (typeof window !== "undefined") {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      );
    }
    return false;
  };
  useEffect(() => {
    const result = isMobile();
    setIsWebsiteUser(!result);
    // localStorage.setItem("auth.strategy", "local");
    // Cookies.set("auth.strategy", "local");
  }, []);

  return isWebsiteUser ? (
    <div className="d-flex sign-up-bg flex-column h-screen bg-[#e8ebef]">
      <div className="w-[100%] el-row el-row--flex">
        <div className="xl:w-1/2 hidden md:block h-full">
          <img
            src="/images/png/log-in-illustration.png"
            alt="Login Page Cover Image"
            className="w-full h-full"></img>
          {/* <Image
            src="/images/png/log-in-illustration.png"
            alt="Login page Cover Image"
            width={760}
            height={760}
            unoptimized
            className="h-full w-full"
          /> */}
        </div>
        <div className="xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xs:w-full mx-auto flex flex-col justify-center items-center py-5">
          <div className="el-card p-2 is-always-shadow w-[600px]">
            <div className="el-card__body">
              <h1 className="text-[2rem] mb-3 text-center">
                Welcome to Linkjoy!
              </h1>
              <h6 className="text-[0.75rem] mt-2 mb-3 font-normal text-secondary text-center">
                Sign In to Linkjoy to start, run and grow your brands&apos;
                digital presence!
              </h6>
              <SignInForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="mb-4 d-flex justify-center mt-4">
        <Image
          src="/images/svg/linkjoy-header-logo.svg"
          alt="Linkjoy Header logo"
          width={75}
          height={30}
          unoptimized
        />
      </div>
      <h2 className="mobile-view-message">
        You seem to be here early! We are not ready for mobile usage yet. We
        strongly recommend you to visit us on the web. Also, all visitor facing
        campaign URLs of Instagram Biolink and Link in Bio are mobile
        responsive. Go grab your audience’s attention with Linkjoy!
      </h2>
    </div>
  );
}

export default SignIn;
