"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import {
  setRecaptchaToken,
  setUserData,
  updateUserData,
} from "@/redux/Slice/authSlice";
import { Eye, EyeOff } from "lucide-react";
import Link from "next/link";
import { IconSquareRoundedX } from "@tabler/icons-react";

import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { signIn } from "@/redux/Thunks/auth/authApi";
import { useRouter } from "next/navigation";
import Cookies from "js-cookie";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "@/components/ui/use-toast";
import { ToastAction } from "@radix-ui/react-toast";
import { findNestedKey } from "@/lib/utils/global-function";
import { MultiStepLoader } from "@/components/ui/multi-step-loader";

// const loadingStates = [
//   {
//     text: "Welcome back User",
//   },
//   {
//     text: "Stirring the digital cauldron",
//   },
//   {
//     text: "Summoning your personalized cosmos",
//   },
//   {
//     text: "Assembling your virtual entourage",
//   },
//   {
//     text: "Polishing your golden ticket to the digital realm",
//   },
// ];

const formSchema = z.object({
  email: z.string().email().min(1, {
    message: "Email address is required",
  }),

  password: z
    .string({
      message: "Password is required",
    })
    .min(8, {
      message: "Password should have minimum of 8 Characters",
    })
    .max(36, {
      message: "Password can have maximum of only 36 Characters",
    }),
});

function SignInForm({ workspaceId }) {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [workspaceLoad, setWorkspaceLoad] = useState(false);

  const isLoading = useAppSelector((state) => state.auth.isLoading);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let formData = {
    email: "",
    password: "",
  };

  const recaptchaRef = useRef(null);

  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY;

  const defaultValues = {
    email: "",
    password: "",
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const batchDispatch = async (captchaCode) => {
    dispatch(updateUserData({ type: "Website" }));
    dispatch(setRecaptchaToken(captchaCode));

    dispatch(signIn())
      .then(unwrapResult)
      .then((res) => {
        if (!res) {
          return;
        }

        Cookies.set("auth.local.token", findNestedKey(res, "token"));
        // Cookies.set("accessToken", findNestedKey(res, "token"));
        Cookies.set("Workspace", JSON.stringify(res.user?.workspace?.id));

        setWorkspaceLoad(true);

        router.push(`/${res.user.workspace.uuid}/dashboard`);
      })
      .catch((error) => {
        // toast({
        // 	variant: "destructive",
        // 	title: error.message || " Something went wrong in Form",
        // 	action: (
        // 		<ToastAction
        // 			onClick={() => form.reset()}
        // 			altText="Try again"
        // 			className="hover:bg-red-100 p-1 rounded-sm"
        // 		>
        // 			Try again
        // 		</ToastAction>
        // 	),
        // });
      });
  };

  const onSubmit = async (data) => {
    // Handle form submission

    formData.email = data.email;
    formData.password = data.password;

    dispatch(setUserData(formData));

    recaptchaRef.current.execute();
  };

  const onReCAPTCHAChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }
    // formData.recaptchaToken = captchaCode;

    batchDispatch(captchaCode);

    recaptchaRef.current.reset();
  };

  // if (workspaceLoad) {
  //   return (
  //     <div className="w-[100vh] h-[100vh] flex items-center justify-center">
  //       {/* Core Loader Modal */}
  //       <MultiStepLoader
  //         loadingStates={loadingStates}
  //         loading={workspaceLoad}
  //         duration={2000}
  //       />

  //       {workspaceLoad && (
  //         <button
  //           className="fixed top-4 right-4 text-black dark:text-white z-[120]"
  //           onClick={() => setWorkspaceLoad(false)}>
  //           <IconSquareRoundedX className="h-10 w-10" />
  //         </button>
  //       )}
  //     </div>
  //   );
  // }

  return (
    <Form {...form} method="POST">
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8 w-full pt-4"
        method="POST">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={siteKey}
          onChange={onReCAPTCHAChange}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <span className="font-normal">
                  {" "}
                  Email <span className="text-[#dc3545]">*</span>{" "}
                </span>{" "}
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <div className="flex justify-between items-center">
                  <span className="font-normal">
                    {" "}
                    Password <span className="text-[#dc3545]">*</span>{" "}
                  </span>{" "}
                  {showPassword ? (
                    <Eye
                      color="#595e64"
                      className=" mr-2 w-5 cursor-pointer"
                      onClick={() => toggleShowPassword()}
                    />
                  ) : (
                    <EyeOff
                      color="#595e64"
                      className=" mr-2 w-5 cursor-pointer"
                      onClick={() => toggleShowPassword()}
                    />
                  )}
                </div>
              </FormLabel>
              <FormControl>
                <Input
                  autoComplete="none"
                  type={showPassword ? "text" : "password"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="!mt-[22px] font-medium">
          <Link
            href="/auth/forgot-password"
            className="text-primary cursor-pointer hover:underline">
            Forgot Password?
          </Link>
        </div>

        <Button isLoading={isLoading} variant="signup" type="submit">
          Sign In
        </Button>

        <div className="!text-[0.875rem] h5 text-center text-secondary pt-4">
          Don&apos;t have a account?{" "}
          <Link
            href="/auth/sign-up"
            className="text-primary cursor-pointer underline">
            Sign Up
          </Link>
        </div>
      </form>
    </Form>
  );
}

export default SignInForm;
