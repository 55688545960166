import { toast } from "@/components/ui/use-toast";

export const getUrl = (url) => {
  if (url.includes(":900")) {
    return `http://${url}`;
  } else {
    return `https://${url}`;
  }
};

const generateUrl = (moduleType, copyObject) => {
  const prefixValue = process.env.NEXT_PUBLIC_EJS_URL;
  let url = "";
  if (moduleType === "hostName") {
    url = copyObject;
  } else {
    if (copyObject.customdomain === null) {
      if (moduleType === "linkInBio") {
        url = getUrl(prefixValue.concat("/l/" + copyObject.project_link));
      }
      if (moduleType === "urlRetargeting") {
        url = getUrl(prefixValue.concat("/t/" + copyObject.actual_url_slug));
      }
      if (moduleType === "pageBuilder") {
        if (copyObject.migrated_link_in_bio_id === null) {
          url = getUrl(prefixValue.concat("/p/" + copyObject.project_link));
        } else {
          url = getUrl(prefixValue.concat("/l/" + copyObject.project_link));
        }
      }
    } else {
      if (moduleType === "urlRetargeting") {
        url = getUrl(
          copyObject.customdomain.domain_name.concat(
            copyObject.actual_url_slug === null
              ? "/"
              : "/" + copyObject.actual_url_slug
          )
        );
      } else {
        url = getUrl(
          copyObject.customdomain.domain_name.concat(
            copyObject.project_link === null
              ? "/"
              : "/" + copyObject.project_link
          )
        );
      }
    }
  }
  return url;
};

export const copyToClipboard = async (moduleType = null, copyObject) => {
  const url = moduleType ? generateUrl(moduleType, copyObject) : copyObject;

  navigator.clipboard
    .writeText(url)
    .then(() => {
      toast({
        variant: "success",
        title: "Url Copied",
      });
    })
    .catch((err) => {
      console.error("Could not copy text: ", err);
      toast({
        variant: "destructive",
        title: "Could not copy URL",
      });
    });
};

export function findNestedKey(obj, key) {
  if (obj.hasOwnProperty(key)) {
    return obj[key];
  }
  for (let i in obj) {
    if (typeof obj[i] === "object") {
      let result = findNestedKey(obj[i], key);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
}
